import { render, staticRenderFns } from "./statistical.vue?vue&type=template&id=57522020&scoped=true&"
import script from "./statistical.vue?vue&type=script&lang=js&"
export * from "./statistical.vue?vue&type=script&lang=js&"
import style0 from "./statistical.vue?vue&type=style&index=0&id=57522020&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57522020",
  null
  
)

export default component.exports