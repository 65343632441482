<template>
  <div style="padding: 20px">
    <div>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="6" :offset="0" class="top-search">
          <span class="search-name">应用名称：</span>
          <el-input
            v-model="app_name"
            placeholder="请输入应用名称"
            size="normal"
          ></el-input>
        </el-col>
        <el-col :span="6" :offset="0" class="top-search">
          <span class="search-name">APPID：</span>
          <el-input
            v-model="appid"
            placeholder="请输入APPID"
            size="normal"
          ></el-input>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-bottom: 20px">
        <el-col :span="8" :offset="0" class="top-search">
          <span class="search-name">支付日期(utc)：</span>
          <el-date-picker
            v-model="dateRange"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd"
            value-format="timestamp"
          />
        </el-col>
        <el-col :span="3">
          <el-button type="primary" size="default" @click="searchData"
            >查询</el-button
          >
        </el-col>
        <el-col :span="3">
          <el-button type="info" size="default" @click="clear">清空</el-button>
        </el-col>
        <el-col :span="3">
          <el-button
            type="primary"
            size="default"
            @click="exportData"
            :loading="downloadLoading"
          >
            导出
          </el-button>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe>
      <el-table-column label="序号" prop="id"></el-table-column>
      <el-table-column label="应用名称" prop="app_name"></el-table-column>
      <el-table-column label="APPID" prop="appid"></el-table-column>
      <el-table-column label="支付日期(utc)" prop="timestamp">
        <template slot-scope="scope">
          <div>{{ scope.row.timestamp | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="BSV收款总额" prop="bsv"></el-table-column>
      <el-table-column label="BTC收款总额" prop="btc"></el-table-column>
      <el-table-column label="欧元应收总额" prop="eur"></el-table-column>
      <el-table-column label="美元应收总额" prop="usd"></el-table-column>
      <el-table-column label="人民币应收总额" prop="cny"></el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getStatistialList } from "@/api/developer.js";
import dayjs from "dayjs";
import { export_json_to_excel } from "@/utils/export2excel";
import { StartTime, EndTime, TimeStampFormat } from "@/utils/helper.js";
export default {
  components: {},
  data() {
    return {
      page: 1,
      pageSize: 20,
      list: [],
      total: 0,
      dateRange: [],
      appid: "",
      app_name: "",
      export: [],
      downloadLoading: false,
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD");
    },
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
          app_name: this.app_name,
          appid: this.appid,
          start_at: StartTime(this.dateRange[0]),
          end_at: EndTime(this.dateRange[1]),
        };

        // console.log(params);
        const { data } = await getStatistialList(params);
        // console.log(data);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async getExport() {
      try {
        const params = {
          page: 1,
          page_size: this.total,
          app_name: this.app_name,
          appid: this.appid,
          start_at: StartTime(this.dateRange[0]),
          end_at: EndTime(this.dateRange[1]),
        };
        const { data } = await getStatistialList(params);
        const { total = 0, items = [] } = data?.data;
        this.export = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    searchData() {
      this.page = 1;
      this.fetchData();
    },
    clear() {
      this.appid = "";
      this.app_name = "";
      this.dateRange = [];
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },
    exportData() {
      let header = [
        "序号",
        "应用名称",
        "APPID",
        "支付日期(utc)",
        "BSV收款总额",
        "BTC收款总额",
        "欧元应收总额",
        "美元应收总额",
        "人民币应收总额",
      ];

      this.getExport().then(() => {
        let newData = [];
        let newDataItem = {};
        this.export.forEach((item, index) => {
          newDataItem = [
            item.id,
            item.app_name,
            item.appid,
            dayjs(item.timestamp * 1000).format("YYYY-MM-DD"),
            item.bsv,
            item.btc,
            item.eur,
            item.usd,
            item.cny,
          ];
          newData.push(newDataItem);
        });
        console.log(header, newData);
        export_json_to_excel({
          header: header,
          data: newData, //具体数据 必填
          filename: `代收款金额统计${TimeStampFormat(
            this.dateRange[0]
          )}-${TimeStampFormat(this.dateRange[1])}`,
          autoWidth: true, //非必填
          bookType: "xlsx", //非必填
        });
        this.downloadLoading = false;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top-search {
  display: flex;
  align-items: center;
  .search-name {
    word-break: keep-all;
    margin-right: 10px;
  }
}
</style>
